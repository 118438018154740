import React from 'react';
import { navigate, graphql } from 'gatsby';
import uspsHook from '../../hooks/uspsHook';
import Layout from '../../components/layout';
import Section from '../../components/Section/Section';
import SectionContent from '../../components/SectionContent/SectionContent';
import SectionLabel from '../../components/SectionLabel/SectionLabel';
import FeatureList from '../../components/FeatureList/FeatureList';
import FeaturedPros from '../../components/FeaturedPros/FeaturedPros';
import Gallery from '../../components/Gallery/Gallery';
import Button from '../../components/Button/Button';
import SEO from '../../components/seo';

export const query = graphql`
  query ($slug: String!) {
    contentfulService (
      fields: {
        slug: {
          eq: $slug
        }
      }
    ) {
      name
      title
      description {
        description
      }
      gallery {
        fluid (
          maxWidth: 800
          maxHeight: 600
          resizingBehavior: FILL
        ) {
          srcSet
          aspectRatio
          src
          sizes
          base64
        }
        id
        title
        description
      }
      usps {
        icon {
          file {
            url
          }
        }
        label
      }
    }
  }
`;

const Service = ({ data }) => {
  const { contentfulService } = data;
  const service = {
    title: contentfulService.title,
    description: contentfulService.description.description,
    gallery: contentfulService.gallery,
    usps: contentfulService.usps.map(({ icon, label }) => ({
      iconUrl: icon.file.url,
      label,
    })),
  };

  const usps = uspsHook();

  return (
    <Layout>
      <SEO title={service.title} />
      <Section>
        <SectionContent centered>
          <h2>{service.title}</h2>
          <p>{service.description}</p>
          <FeatureList
            features={service.usps}
          />
        </SectionContent>
        {service.gallery
          && <Gallery galleryAssets={service.gallery} />
        }
        <SectionContent centered>
          <SectionLabel label="Get a quote" />
          <h2>
            Talk to us about
            <br />
            your next project
          </h2>
          <Button onClick={() => navigate('/#contact')} label="Get in touch" cta />
        </SectionContent>
      </Section>

      <Section theme="dark">
        <FeaturedPros
          pros={usps}
        />
      </Section>
    </Layout>
  );
};

export default Service;
